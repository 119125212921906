import { gql, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import deleteEditorComment from '@/editor/utils/deleteEditorComment';
import { updateEditorStateAsync } from '@/editor/actions/updateEditorState';
import { updateTitleEditorStateAsync } from '@/editor/actions/updateTitleEditorState';
import syncEditorComments from '@/editor/utils/syncEditorComments';
import { getTitleEditorComments, getEditorComments } from '@/editor/selectors';
import useComments from '@/comments/hooks/useComments';

const UPDATE_COMMENT_MUTATION = gql`
  mutation UpdateCommentMutation($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      __typename
      id
      visibility
      content
      resolved
    }
  }
`;

const RESOLVE_COMMENT_MUTATION = gql`
  mutation ResolveCommentMutation($input: ResolveCommentInput!) {
    resolveComment(input: $input) {
      __typename
      id
      resolved
      model {
        ... on CommentInterface {
          __typename
          id
          total_comments
        }
      }
    }
  }
`;

const DELETE_COMMENT_MUTATION = gql`
  mutation DeleteCommentMutation($id: ID!) {
    deleteComment(id: $id) {
      __typename
      id
      deleted_at
      model {
        ... on CommentInterface {
          __typename
          id
          total_comments
        }
      }
    }
  }
`;

/**
 *
 * @param {object} data
 * @param {object} data.comment
 * @returns {{updateComment: Function, loadingUpdateMutation: boolean, resolveComment: Function, loadingResolveComment: boolean, deleteComment: Function, loadingDeleteComment: boolean}}
 */
export default function useComment({ comment }) {
  const { comments } = useComments();
  const dispatch = useDispatch();
  const titleEditorComments = useSelector(getTitleEditorComments);
  const contentEditorComments = useSelector(getEditorComments);
  const contentEditorState = useSelector((state) => state?.editor?.editorState);
  const titleEditorState = useSelector((state) => state?.editor?.titleEditorState);

  // Mutations
  const [updateComment, { loading: loadingUpdateMutation }] = useMutation(UPDATE_COMMENT_MUTATION);
  const [resolveComment, { loading: loadingResolveComment }] = useMutation(
    RESOLVE_COMMENT_MUTATION,
    {
      onCompleted(data) {
        if (comment.editor_key) {
          handleOnEditorCommentResolveOrReopen(data.resolveComment.resolved);
        }
      },
    },
  );
  const [deleteComment, { loading: loadingDeleteComment }] = useMutation(DELETE_COMMENT_MUTATION, {
    onCompleted() {
      if (comment.editor_key) {
        handleOnEditorCommentRemove({
          key: comment.editor_key,
          ranges: JSON.parse(comment.editor_ranges),
        });
      }
    },
  });

  function handleOnEditorCommentRemove(editorComment) {
    if (titleEditorComments.some((c) => c.key === editorComment.key)) {
      dispatch(updateTitleEditorStateAsync(deleteEditorComment(editorComment, titleEditorState)));
    } else {
      dispatch(updateEditorStateAsync(deleteEditorComment(editorComment, contentEditorState)));
    }
  }

  function handleOnEditorCommentResolveOrReopen(newResolvedValue) {
    const editorComment = {
      key: comment.editor_key,
      ranges: JSON.parse(comment.editor_ranges),
    };
    // The comments data may be stale as this callback may be called before the state is changed.
    const updatedComments = comments.map((staleComment) => {
      if (staleComment.id === comment.id) {
        return {
          ...staleComment,
          resolved: newResolvedValue,
        };
      }
      return staleComment;
    });

    if (titleEditorComments.some((c) => c.key === editorComment.key)) {
      dispatch(
        updateTitleEditorStateAsync(
          syncEditorComments(titleEditorState, titleEditorComments, updatedComments),
        ),
      );
    } else {
      dispatch(
        updateEditorStateAsync(
          syncEditorComments(contentEditorState, contentEditorComments, updatedComments),
        ),
      );
    }
  }

  return {
    updateComment,
    loadingUpdateMutation,
    resolveComment,
    loadingResolveComment,
    deleteComment,
    loadingDeleteComment,
  };
}
