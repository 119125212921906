import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import TextEditorWithUserMentions from '@/storychief/components/editors/TextEditorWithUserMentions';
import {
  COMMENT_VISIBILITY_INTERNAL,
  COMMENT_VISIBILITY_PUBLIC,
} from '@/comments/constants/Constants';
import CommentVisibilitySelect from '@/comments/components/CommentVisibilitySelect';
import { usePrevious } from '@/storychief/hooks';
import { commentsUnsavedVar } from '@/graphql/cache';
import StoryChief from '@/storychief';
import { ROLE_CLIENT } from '@/users/constants/Constants';
import useUsersForMentions from '@/users/hooks/useUsersForMentions';
import Button from '@/storychief/components/Button';
import useComments from '@/comments/hooks/useComments';

const propTypes = {
  comment: PropTypes.shape({
    content: PropTypes.string,
  }),
  isAutofocus: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'reply', 'editor', 'editor-reply']),
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isAnimated: PropTypes.bool,
  isShowVisibilitySelect: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onFocus: PropTypes.func,
};
const defaultProps = {
  comment: null,
  isReadOnly: false,
  isDisabled: false,
  isAutofocus: false,
  variant: 'default',
  isAnimated: false,
  isShowVisibilitySelect: true,
  onFocus: () => {},
  onCancel: () => {},
};

function CommentInput({
  comment,
  isAutofocus,
  variant,
  isReadOnly,
  isDisabled,
  isAnimated,
  isShowVisibilitySelect,
  onSubmit,
  onFocus,
  onCancel,
}) {
  const { modelType } = useComments();

  // Hooks
  const prevIsReadOnly = usePrevious(isReadOnly);

  // Variables
  const isCurrentUserGuest = !StoryChief?.user?.id;
  const isCurrentUserClient = StoryChief?.user?.role === ROLE_CLIENT;

  // States
  const [content, setContent] = useState(comment?.content || null);
  const [visibility, setVisibility] = useState(
    isCurrentUserClient ? COMMENT_VISIBILITY_PUBLIC : COMMENT_VISIBILITY_INTERNAL,
  );
  const [isActive, setIsActive] = useState(!isReadOnly && isAutofocus);
  const [isFocused, setIsFocused] = useState(false);
  const [isClientTagged, setIsClientTagged] = useState(false);

  const { data: { usersForMentions } = { usersForMentions: [] } } = useUsersForMentions();

  // Refs
  const id = useRef();
  if (!id.current) {
    id.current = uniqueId('comment-input-');
  }

  // Functions
  function isSubmitDisabled() {
    return !content || isDisabled;
  }

  function handleOnSubmit() {
    onSubmit({ content, visibility });
    removeUnsavedComment();
  }

  function handleOnFocus() {
    setIsActive(true);
    onFocus();
    setIsFocused(true);
  }

  function handleOnCancel() {
    setIsActive(false);
    onCancel();
    removeUnsavedComment();
  }

  function addUnsavedComment() {
    if (!commentsUnsavedVar().has(id.current)) {
      const newUnsavedComments = new Set(commentsUnsavedVar());
      newUnsavedComments.add(id.current);
      commentsUnsavedVar(newUnsavedComments);
    }
  }

  function removeUnsavedComment() {
    if (commentsUnsavedVar().has(id.current)) {
      const newUnsavedComments = new Set(commentsUnsavedVar());
      newUnsavedComments.delete(id.current);
      commentsUnsavedVar(newUnsavedComments);
    }
  }

  function handleOnVisibilityChange(newValue) {
    setVisibility(newValue);
  }

  useEffect(() => {
    if (!isReadOnly && isAutofocus) {
      setIsActive(true);
    }

    if (isReadOnly && !prevIsReadOnly) {
      setIsActive(false);
    }
  }, [isReadOnly]);

  useEffect(
    () => () => {
      removeUnsavedComment();
    },
    [],
  );

  useEffect(() => {
    checkForClientTags();
  }, [content]);

  function getTaggedUserIds() {
    const contentParsed = JSON.parse(content);

    if (!contentParsed?.entityMap) {
      return [];
    }

    if (Object.keys(contentParsed.entityMap).length === 0) {
      return [];
    }

    return Object.keys(contentParsed.entityMap)
      .map((key) => contentParsed.entityMap[key])
      .flat()
      .filter((mention) => mention.type === 'mention')
      .map((mention) => mention.data.userId);
  }

  function getTaggedClientUsers() {
    const taggedUserIds = getTaggedUserIds();

    if (taggedUserIds.length < 1) {
      return [];
    }

    return usersForMentions
      .filter((user) => taggedUserIds.includes(user.id))
      .filter((user) => user.role === ROLE_CLIENT);
  }

  function checkForClientTags() {
    if (isClientTagged !== getTaggedClientUsers().length > 0) {
      setIsClientTagged(getTaggedClientUsers().length > 0);
    }
  }

  function handleOnContentChange(newContent) {
    if (newContent) {
      addUnsavedComment();
    } else {
      removeUnsavedComment();
    }

    setContent(newContent);
  }

  return (
    <div
      data-testid="comment-input"
      className={classNames('comment-input', {
        'comment-input--readonly': isReadOnly,
        'comment-input--disabled': isDisabled,
        'comment-input--active': isActive,
        'comment-input--focused': isFocused,
      })}
    >
      <TextEditorWithUserMentions
        users={usersForMentions}
        onContentChange={handleOnContentChange}
        content={content}
        placeholder={
          ['reply', 'editor-reply'].includes(variant)
            ? 'Reply'
            : 'Send your colleagues a notification by typing @'
        }
        allowNewLines
        focusOnMount={isAutofocus}
        formControl={false}
        formControlAutoHeight
        isDraftJs
        assistant={false}
        disabled
        emojiPicker
        emojiPickerPlacement="bottom"
        mentionPicker
        readOnly={isReadOnly || isDisabled}
        debounceContentChange={false}
        onReturn={handleOnSubmit}
        onFocus={handleOnFocus}
        onBlur={() => setIsFocused(false)}
        actionsPosition="footer"
        isAnimateFooterToggle={isAnimated}
        isShowFooter={isActive}
        customFooter={
          <>
            {isShowVisibilitySelect && !isCurrentUserGuest && !isCurrentUserClient && (
              <span className="gutter-right-1">
                <CommentVisibilitySelect value={visibility} onChange={handleOnVisibilityChange} />
              </span>
            )}

            <Button
              type="button"
              onClick={handleOnCancel}
              variant="secondary"
              size="xs"
              aria-label="Cancel"
              disabled={isDisabled}
            >
              <span className="regular">Cancel</span>
            </Button>
            {!isReadOnly && (
              <span className="gutter-left-1">
                {/* Clients can only be tagged if they are a collaborator, except with tasks */}
                {modelType !== 'Task' &&
                isClientTagged &&
                visibility === COMMENT_VISIBILITY_INTERNAL ? (
                  <OverlayTrigger
                    placement="top"
                    delayShow={0}
                    overlay={
                      <Tooltip id="visibility-tooltip">
                        The following users cannot see private comments:
                        <ul>
                          {getTaggedClientUsers().map((user) => (
                            <li key={user.id}>
                              {user.firstname} {user.lastname}
                            </li>
                          ))}
                        </ul>
                      </Tooltip>
                    }
                  >
                    <Button size="xs" disabled aria-label="Submit">
                      {comment ? 'Save' : 'Submit'}
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <Button
                    size="xs"
                    aria-label="Submit"
                    disabled={isSubmitDisabled()}
                    onClick={handleOnSubmit}
                  >
                    {comment ? 'Save' : 'Submit'}
                  </Button>
                )}
              </span>
            )}
          </>
        }
      />
    </div>
  );
}

CommentInput.propTypes = propTypes;
CommentInput.defaultProps = defaultProps;

export default CommentInput;
