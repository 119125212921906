import PropTypes from 'prop-types';
import { DropdownButton, MenuItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import {
  COMMENT_VISIBILITY_INTERNAL,
  COMMENT_VISIBILITY_PUBLIC,
} from '@/comments/constants/Constants';
import { TOOLTIP_DELAY } from '@/storychief/constants/Constants';

const propTypes = {
  value: PropTypes.oneOf([COMMENT_VISIBILITY_INTERNAL, COMMENT_VISIBILITY_PUBLIC]).isRequired,
  onChange: PropTypes.func.isRequired,
};
const defaultProps = {};

function CommentVisibilitySelect({ value, onChange }) {
  function getLabel(val) {
    switch (val) {
      case COMMENT_VISIBILITY_PUBLIC:
        return (
          <span>
            <Icon icon="fa:globe" inline className="mr-1" /> Public
          </span>
        );
      case COMMENT_VISIBILITY_INTERNAL:
      default:
        return (
          <span>
            <Icon icon="fa:lock" width="14" height="14" inline className="mr-1" /> Team
          </span>
        );
    }
  }

  function getVisibilityTooltipMessage() {
    if (value === COMMENT_VISIBILITY_INTERNAL) {
      return 'Visible to team members only';
    }

    return 'Visible to team members and external users';
  }

  return (
    <OverlayTrigger
      placement="top"
      delayShow={TOOLTIP_DELAY}
      overlay={<Tooltip id="visibility-tooltip">{getVisibilityTooltipMessage()}</Tooltip>}
    >
      <DropdownButton
        id="comment-visibility-dropdown"
        title={
          <span className="regular">
            {getLabel(value)}
            <em className="icon-angle-down" />
          </span>
        }
        noCaret
        bsStyle={null}
        className="btn-chromeless"
        pullRight
        onSelect={onChange}
      >
        {value === COMMENT_VISIBILITY_PUBLIC ? (
          <MenuItem eventKey={COMMENT_VISIBILITY_INTERNAL}>
            {getLabel(COMMENT_VISIBILITY_INTERNAL)}
          </MenuItem>
        ) : (
          <MenuItem eventKey={COMMENT_VISIBILITY_PUBLIC}>
            {getLabel(COMMENT_VISIBILITY_PUBLIC)}
          </MenuItem>
        )}
      </DropdownButton>
    </OverlayTrigger>
  );
}

CommentVisibilitySelect.propTypes = propTypes;
CommentVisibilitySelect.defaultProps = defaultProps;

export default CommentVisibilitySelect;
